.my-tooltip {
    position: absolute;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
  }
  
  .my-tooltip:hover {
    opacity: 1;
    pointer-events: auto;
  }
  
  .lavenderBackground {
    background-color:  #E6E6FA; /* You can use any desired color */
    padding: 1px; /* Optional: Add padding for better visual appearance */
    border-radius: 4px; /* Optional: Add rounded corners for better visual appearance */
  }